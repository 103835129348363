import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: ${({ theme }) => theme.spacing[24]} auto;
  width: 100%;

  @media (min-width: 601px) {
    width: 90%;
  }

  @media (min-width: 993px) {
    width: 80%;
  }

  @media print {
    width: 100%;
  }
`;

export const ErrorDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing[24]};
  gap: ${({ theme }) => theme.spacing[6]};
`;

export const Text = styled.div`
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.grey[700]};
  font-size: ${({ theme }) => theme.fonts.size.xl3};
  font-weight: ${({ theme }) => theme.fonts.weight};
`;
export const SubText = styled.div`
  width: 22.5625rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey[500]};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.fonts.size.base};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  line-height: 150%;
`;
