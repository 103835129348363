import ErrorPage from 'modules/errorPage';
import Layout from 'ui/LayoutV2';

const NotFound = () => (
  <>
    <Layout headerVariant={3} footerVariant={2}>
      <ErrorPage />
    </Layout>
  </>
);

export default NotFound;
